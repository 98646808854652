import { useState } from "react";

const Flare = () => (
  <svg
    width="92px"
    height="40px"
    viewBox="0 0 92 40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <radialGradient
        cx="72.6449925%"
        cy="55.2261547%"
        fx="72.6449925%"
        fy="55.2261547%"
        r="30.056461%"
        gradientTransform="translate(0.726450,0.552262),scale(0.434783,1.000000),rotate(137.384715),translate(-0.726450,-0.552262)"
        id="radialGradient-1"
      >
        <stop stopColor="#FFCD5F" offset="0%"></stop>
        <stop stopColor="#F67407" offset="100%"></stop>
      </radialGradient>
    </defs>
    <g fill="url(#radialGradient-1)" fillRule="nonzero">
      <path
        d="M59.7641462,37.6247328 L60.3819314,34.9613932 C61.2576421,31.9789469 60.9335602,29.2264397 59.4579532,27.1975647 C58.0788812,25.3309996 55.8309939,24.2354071 53.0797454,24.1069116 L0.999092513,23.4576716 C0.66742326,23.453008 0.35906594,23.2894753 0.173486928,23.0198242 C-0.0120920847,22.7501731 -0.0514088683,22.4085263 0.0682189347,22.1050882 C0.249133517,21.5932704 0.723943492,21.2366849 1.27490691,21.1988574 L53.858921,20.5225657 C60.0647449,20.2452861 66.8497789,15.2813051 69.2148874,9.22173158 L72.2074735,1.539058 C72.9432185,0.338056055 73.6126229,-0.160045639 74.2156868,0.0447529169 C74.8187506,0.249551473 75.0030353,1.02024416 74.768541,2.35683098 L72.7084622,9.36144874 C71.8294952,12.3722055 72.1522409,15.1508404 73.6286307,17.1989743 C75.1406066,19.1633007 77.4857463,20.3164551 79.9736736,20.3189648 L91.036297,21.0016761 C91.3500215,21.0112528 91.6405419,21.1683389 91.8191269,21.4249571 C92.0086059,21.6993438 92.0521426,22.0481642 91.9358647,22.3602716 C91.7556961,22.8769477 91.2828438,23.2369181 90.7341521,23.2751047 L79.245779,23.957816 C73.0037401,24.2445547 66.2810162,29.2488284 63.9256594,35.3590944 L62.2175451,38.7747836 C61.5766489,39.7256849 60.9647777,40.123084 60.3819314,39.9669809 C59.7990852,39.8108778 59.5931568,39.0301284 59.7641462,37.6247328 Z"
        id="Path"
      ></path>
    </g>
  </svg>
);

function App() {
  const [files, setFiles] = useState([]);

  return (
    <main style={{ margin: 32 }}>
      <div
        style={{
          fontSize: 24,
          display: "flex",
          alignItems: "center",
          marginBottom: 64,
        }}
      >
        <Flare />
        <div style={{ position: "relative", top: 24, left: -8 }}>
          <strong style={{ marginRight: 8 }}>Workers AI</strong> Image
          Captioning
        </div>
      </div>
      <div
        style={{
          padding: 64,
          backgroundColor: "rgba(0,0,0,0.05)",
          borderRadius: 7,
          marginBottom: 32,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid white",
          outline: "2px dashed rgba(0,0,0,0.1)",
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={async (e) => {
          e.preventDefault();

          [...e.dataTransfer.items]
            .filter((item) => item.kind === "file")
            .forEach(async (item, i) => {
              const file = item.getAsFile();

              const id = Math.floor(Math.random() * 100000000);

              setFiles((files) => [
                ...files,
                {
                  id,
                  description: null,
                  content: URL.createObjectURL(file),
                },
              ]);

              const fileData = new Uint8Array(await file.arrayBuffer());

              const response = await fetch("/api", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: fileData,
              })
                .then((res) => res.json())
                .catch((e) => console.log(e));

              setFiles((files) => {
                const idx = files.findIndex((file) => file.id === id);

                return [
                  ...files.slice(0, idx),
                  {
                    id,
                    description: response.description,
                    content: URL.createObjectURL(file),
                  },
                  ...files.slice(idx + 1),
                ];
              });
            });
        }}
      >
        Drop images here to generate captions
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gap: 16,
        }}
      >
        {files.map((file) => (
          <div>
            <img style={{ width: "100%" }} src={file.content} />
            <p style={{ margin: "8px 0", fontStyle: "italic" }}>
              {file.description}
            </p>
          </div>
        ))}
      </div>
    </main>
  );
}

export default App;
